import { call, put, fork, all, takeLatest } from 'redux-saga/effects';
import type { AllEffect, CallEffect, ForkEffect, PutEffect } from 'redux-saga/effects';
import { createLogger } from 'redux-logger';
import { setDashboardMetricData } from '../../actions/Dashboard/dashboardActions';
import { updateDataLoadingStatus } from '../../actions/CommonConfig/commonConfigActions';
import { GET_DASHBOARD_EXCEPTION_METRIC_DATA, GET_DASHBOARD_LOG_METRIC_DATA, GET_DASHBOARD_REPLAY_METRIC_DATA } from '../../actions/ActionTypes/Dashboard/dashboardTypes';
import { apiRequest } from '../../../services/axiosInterceptor';
import { formatChartResponsePayload } from '../../../helpers/dashboardHelper';
import apiURL from '../../../constants/apiEndpointConstants';
import { API_SUCCESS_STATUS_CODE } from '../../../constants/commonConstants';

const callEffect: any = call;

export function * fetchDashboardLogMetricData ({ payload }: any): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const { timeRangeType, ...reqData } = payload?.data ?? {};
    const config = {
      method: 'POST',
      url: reqData?.dashboardType ? `${apiURL.GET_DASHBOARD_LOG_COMPONENT_SERVICE_METRICS}${timeRangeType}` : `${apiURL.GET_DASHBOARD_LOG_METRICS}${timeRangeType}`,
      data: reqData
    };

    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    if (response?.status === API_SUCCESS_STATUS_CODE) {
      let responseData = { ...response?.data };
      if (payload.data.dashboardType) {
        responseData = formatChartResponsePayload(responseData);
      }
      yield put(setDashboardMetricData(responseData, 'logs'));
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    yield put(updateDataLoadingStatus(-1));
    createLogger(err);
  }
}

export function * fetchDashboardExceptionMetricData ({ payload }: any): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const { timeRangeType, ...reqData } = payload?.data ?? {};
    const config = {
      method: 'POST',
      url: reqData.dashboardType ? `${apiURL.GET_DASHBOARD_EXCEPTION_COMPONENT_SERVICE_METRICS}${timeRangeType}` : `${apiURL.GET_DASHBOARD_EXCEPTION_METRICS}${timeRangeType}`,
      data: reqData
    };

    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    if (response?.status === API_SUCCESS_STATUS_CODE) {
      let responseData = { ...response?.data };
      if (payload.data.dashboardType) {
        responseData = formatChartResponsePayload(responseData);
      }
      yield put(setDashboardMetricData(responseData, 'exceptions'));
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    yield put(updateDataLoadingStatus(-1));
    createLogger(err);
  }
}

export function * fetchDashboardReplayMetricData ({ payload }: any): Generator<CallEffect<any> | PutEffect<any>, void> {
  try {
    const config = {
      method: 'POST',
      url: apiURL.GET_DASHBOARD_REPLAY_METRICS,
      data: payload?.data,
      retry: 2
    };

    yield put(updateDataLoadingStatus(1));
    const response: any = yield callEffect(apiRequest, config);

    if (response?.status === API_SUCCESS_STATUS_CODE) {
      yield put(setDashboardMetricData(response?.data, 'replays'));
    }
    yield put(updateDataLoadingStatus(-1));
  } catch (err: any) {
    yield put(updateDataLoadingStatus(-1));
    createLogger(err);
  }
}

export function * getDashboardReplayMetricData (): Generator<ForkEffect<never>> {
  yield takeLatest(GET_DASHBOARD_REPLAY_METRIC_DATA, fetchDashboardReplayMetricData);
}

export function * getDashboardLogMetricData (): Generator<ForkEffect<never>> {
  yield takeLatest(GET_DASHBOARD_LOG_METRIC_DATA, fetchDashboardLogMetricData);
}

export function * getDashboardExceptionMetricData (): Generator<ForkEffect<never>> {
  yield takeLatest(GET_DASHBOARD_EXCEPTION_METRIC_DATA, fetchDashboardExceptionMetricData);
}

export default function * root (): Generator<AllEffect<any>> {
  yield all([fork(getDashboardLogMetricData), fork(getDashboardExceptionMetricData), fork(getDashboardReplayMetricData)]);
}
